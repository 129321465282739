import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import pastHeaderImg from "../images/PAST EVENTS.svg";
import queensCoverImage from "../images/queens-cover-image.jpg"; // -25-mozjpeg-80
import manhattanCoverImage from "../images/manhattan-cover-image.jpg";
import statenIslandCoverImage from "../images/staten-island-cover-image.jpg";
import prospectParkCoverImage from "../images/prospect-park-cover-image.jpg";
import sunsetParkCoverImage from "../images/sunset-park-cover-image.jpg";
import BATCoverImage from "../images/BAT-cover-image.jpg";
import bronxCoverImage from "../images/bronx/bx-23.jpg"
import { Link } from "gatsby";
import HeaderLogo from "../images/pipp-header-logo.svg";

const Past = class extends React.Component {
  constructor() {
    super();
  }

  render() {
    const ListLink = (props) => (
      <li className="header-list-element">
        <Link to={props.to} className="header-list-link">{props.children}</Link>
      </li>
    );

    return (
      <Layout>
        <SEO title="Past" />
        <main className="list-main past-main">
          <header className="nav-header">
            <nav>
              <Link className="logo-link" to="/">
                <img src={HeaderLogo} alt="Home" />
              </Link>
              <div className="heading-container">
                <ul className="header-ul">
                  <ListLink to="/about">about</ListLink>
                  <ListLink to="/manifesto">manifesto</ListLink>
                  <ListLink to="/upcoming">upcoming</ListLink>
                  <ListLink to="/past">past</ListLink>
                  <ListLink to="/resources">resources</ListLink>
                  <ListLink to="/contact">contact</ListLink>
                </ul>
                <img className="heading-img" src={pastHeaderImg} alt="Past" />
              </div>
            </nav>
          </header>
          <header className="date-header">
            <h2 style={{ textAlign: "center" }}>2020</h2>
          </header>
          <section className="list-section past-section">
            <div className="list-section-image-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/QL45tSl9axM"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              />
            </div>
            <div className="list-section-content">
              <header>
                <h3>
                  Making a Pattern for a Simple Knit Sweater with Elinor Vizard
                </h3>
              </header>
              <p>
                Elinor Vizard shows how to plan out and make a pattern for a
                simple, cozy and chunky knit sweater. She demonstrates how to
                translate the measurements from a knit swatch into the pattern
                for a boxy sweater. Elinor talks about different variations of
                hem starts and necklines along the way, and discusses how to go
                about making a unique creation. This sweater can be worn by
                anyone, and she encourages adapting the pattern to fit any
                style.
              </p>
              <p>
                E-mail with questions for Elinor at ea.vizard at gmail dot com,
                visit her website at elinor-vizard.squarespace.com and on
                Instagram @elinorvizard, and if you have a bit to spare, please
                consider donating to her through Venmo @Elinor-Vizard in
                recognition of her work.
              </p>
            </div>
          </section>
          <section className="list-section past-section">
            <div className="list-section-image-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/GjA5_mNnMTY"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              />
            </div>
            <div className="list-section-content">
              <header>
                <h3>A Conversation with Cal Patch</h3>
              </header>
              <p>
                Tune in for a conversation with celebrated patternmaking teacher
                and author Cal Patch. Learn about her creative story, her
                innovative philosophy on teaching patterns, her book
                (&quot;Design-It-Yourself Clothes: Patternmaking
                Simplified&quot;), the future of patternmaking, and more.
              </p>
              <p>
                E-mail with questions for Cal at cal at calpatch dot com, visit
                her on Instagram @hodgepodgefarm, and check out her book and
                upcoming classes at calpatch.com
              </p>
            </div>
          </section>
          <section className="list-section past-section">
            <div className="list-section-image-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/DfNugWOvtL4"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              />
            </div>
            <div className="list-section-content">
              <header>
                <h3>
                  Manipulación y Aplicación de Pinzas / Dart Manipulations and
                  Application with Jasmin Moran
                </h3>
              </header>
              <p>
                Jasmin demostra cómo hacer manipulaciones de pinzas en un bloque
                de un patronaje de una camisa y demostra cómo aplicar pinzas en
                una camisa de hombre para un ajuste femenino. / Jasmin
                demonstrates how to make dart manipulations on a front bodice
                sloper, and shows how to apply darts on to a men's shirt for a
                feminine fit. The demonstration is in Spanish, in an effort to
                break the language barrier that people face in the latinx
                community. The Q&A portion is in English and Spanish.
              </p>
              <p>
                E-mail with questions for Jasmin at jasminivettemoran at gmail
                dot com, visit her website at jasminivette.com and on Instagram
                @jasminivette, and if you have a bit to spare, please consider
                donating to her through Venmo @jasminivette in recognition of
                her work.
              </p>
            </div>
          </section>
          <section className="list-section past-section">
            <div className="list-section-image-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/TGtmH-LKlLw"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              />
            </div>
            <div className="list-section-content">
              <header>
                <h3>
                  DIY Elastic Waist Skirt with Daddy Dressed Me by Michael
                  Gardner
                </h3>
              </header>
              <p>
                Together with his daughter, Michael Gardner of Daddy Dressed Me
                by MG shows how to take measurements, self-draft a pattern, and
                cut fabric for an elastic waist skirt. Michael and Ava go step
                by step through the process. After this recording, Ava used the
                pattern to sew her own skirt.
              </p>
              <p>
                E-mail with questions for Michael at daddydressedmebymg at gmail
                dot com, and if you have a bit to spare, please consider
                donating to his through Venmo @Michael-Gardner-235 in
                recognition of his work. Check out his work on Instagram
                @daddydressedmebymg.
              </p>
            </div>
          </section>
          <section className="list-section past-section">
            <div className="list-section-image-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/NJFt20_d89s"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              />
            </div>
            <div className="list-section-content">
              <header>
                <h3>T-Shirt Fitting and Alteration with Lala Sondjaja</h3>
              </header>
              <p>
                In this free demonstration, Lala Sondjaja shows how to alter
                unisex/men's t-shirts to fit better. From armholes to necklines,
                hems, and even bust darts, Lala breaks down the process of
                assessing and addressing t-shirt fit issues. Working solo
                &#40;as so many of us do when trying to fit clothes&#41;, she
                fits onto a woman's body &#40;her own body&#41;, but you can
                take the same concepts and apply them to anyone's body. Lala
                also briefly discusses and demonstrates sewing with knit
                fabrics.
              </p>
              <p>
                E-mail with questions for Lala at hello at louisamerry dot com,
                and if you have a bit to spare, please consider donating to her
                through Venmo @ksondjaja in recognition of her work.
              </p>
            </div>
          </section>
          <section className="list-section past-section">
            <div className="list-section-image-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/7tdv3iIO7Mw"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              />
            </div>
            <div className="list-section-content">
              <header>
                <h3>Patternmaking from the Body with Julia John</h3>
              </header>
              <p>
                In this free demonstration, Julia John shows how to pattern a
                pair of drawstring pants directly from your body. By tracing the
                body directly to the pattern paper, you can feel free to
                experiment with styles and silhouettes and to engage fully with
                the process of patternmaking. The pants &mdash; ideal for
                dancing on the sidewalk, walking the dog, or snuggling up on a
                Saturday &mdash; can be made from many materials and are a great
                beginner project. Participants will have opportunities to
                consider how to expand and extend these techniques while
                patterning other garments beyond this demonstration. This
                demonstration is excellent for tweens and teens &#40;ages
                10-15&#41; but will benefit all, younger and older. It applies
                to all gender identities and body types.
              </p>
              <p>
                Visit her work on Instagram at @juliajohnonjohnstreet, e-mail
                with questions at julia.john95 at gmail dot com, and if you have
                a bit to spare, please consider donating to her through Venmo
                @Julia-John in recognition of her work.
              </p>
              <p>
                To access Julia John's instructional handout for this
                demonstration, visit{" "}
                <Link to="/resources" className="cta">
                  Resources
                </Link>
                .
              </p>
            </div>
          </section>
          <section className="list-section past-section">
            <div className="list-section-image-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/NBda_itPPrE"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="true"
              />
            </div>
            <div className="list-section-content">
              <header>
                <h3>The Thrift Update with Cleveland Designs & Tailoring</h3>
              </header>
              <p>
                In this free patternmaking demonstration, Victoria Cleveland
                showed how to quickly pattern a cute summer pleated pant using a
                basic pant block. Later she'll be using this pattern, along with
                another that she drafts, to repurpose a thrifted maxi dress into
                a trendy summer two-piece top and pants set.
              </p>
              <p>
                Visit her work on Instagram at @clevelanddesignstailoring,
                e-mail with questions at victoria at vclevelanddesigns dot com,
                and if you have a bit to spare, please consider donating to her
                through Venmo @Victoria-Cleveland-1 in recognition of her work.
              </p>
            </div>
          </section>
          <section className="list-section past-section">
            <div className="list-section-image-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/69Cew2GOICI"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              />
            </div>
            <div className="list-section-content">
              <header>
                <h3>Simple Zero Waste Top with Amy Sperber</h3>
              </header>
              <p>
                In this free demonstration, Amy Sperber will show how to utilize
                fabric in a way that creates little to no waste. This method is
                a design-as-you-draft approach and has endless possibilities.
              </p>
              <p>
                Visit her work on Instagram at @amy_sperber, e-mail with
                questions at amy_sperber at fitnyc dot edu, and if you have a
                bit to spare, please consider donating to the Fashion and Race
                Database at{" "}
                <a class="cta" href="https://fashionandrace.org/database">
                  fashionandrace.org/database
                </a>
                .
              </p>
              <p>
                To access Amy Sperber's jacket pattern referenced in minute 44
                of the video, visit{" "}
                <Link to="/resources" className="cta">
                  Resources
                </Link>
                .
              </p>
            </div>
          </section>
          <header style={{ marginBottom: "-2rem" }}>
            <h2 style={{ textAlign: "center" }}>2019</h2>
          </header>
          <article className="past-article">
            <p style={{ marginBottom: "2.5rem" }}>
              Click to see images from each event.
            </p>
          </article>
          <section className="three-grid">
            <Link className="grid-link" to="/2019-bronx">
              <img src={bronxCoverImage} alt="" />
              <div className="space">
                <p>
                  <i>Patternmaking with Children - Boxy Top</i>
                </p>
                <p>Van Cortlandt Park, Bronx</p>
                <p>08.25.2019</p>
              </div>
            </Link>
            <Link className="grid-link" to="/2019-queens">
              <img src={queensCoverImage} alt="" />
              <div className="space">
                <p>
                  <i>Patternmaking Without Numbers - Hoodie</i>
                </p>
                <p>Flushing Meadows Corona Park, Queens</p>
                <p>08.17.2019</p>
              </div>
            </Link>
            <Link className="grid-link" to="/2019-manhattan">
              <img src={manhattanCoverImage} alt="" />
              <div className="space">
                <p>
                  <i>Draping on a Human Being - Shift Dress</i>
                </p>
                <p>Central Park, Manhattan</p>
                <p>08.03.2019</p>
              </div>
            </Link>
            <Link className="grid-link" to="/2019-staten-island">
              <img src={statenIslandCoverImage} alt="" />
              <div className="space">
                <p>
                  <i>Drafting a Custom T-Shirt</i>
                </p>
                <p>Clove Lakes Park, Staten Island</p>
                <p>07.21.2019</p>
              </div>
            </Link>
            <Link className="grid-link" to="/2019-brooklyn-prospect-park">
              <img src={prospectParkCoverImage} alt="" />
              <div className="space">
                <p>
                  <i>Patternmaking a Preexisting Garment - Dress</i>
                </p>
                <p>Prospect Park, Brooklyn</p>
                <p>07.07.2019</p>
              </div>
            </Link>
            <Link className="grid-link" to="/2019-brooklyn-sunset-park">
              <img src={sunsetParkCoverImage} alt="" />
              <div className="space">
                <p>
                  <i>Patternmaking a Preexisting Garment - Shorts</i>
                </p>
                <p>Sunset Park, Brooklyn</p>
                <p>06.09.2019</p>
              </div>
            </Link>
            <Link className="grid-link" to="/2019-brooklyn-bat">
              <img src={BATCoverImage} alt="" />
              <div className="space">
                <p>
                  <i>Pilot - T-Shirt</i>
                </p>
                <p>Brooklyn Army Terminal, Brooklyn</p>
                <p>03.15.2019</p>
              </div>
            </Link>
          </section>
        </main>
      </Layout>
    );
  }
};

export default Past;
